import React, { useEffect, useState } from "react";
import "./app-payment.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Payment from "../../components/payment/payment.component";
import SuccessPayment from "../../components/success-payment/success-payment.component";
import { SERVER_URL } from "../../constants";
const AppPaymentPage = () => {
  const app = useSelector((state) => state.app);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const succeed = params.get("succeed");
  const [userInfo, setUserinfo] = useState();
  useEffect(() => {
    if (token) {
      let url = `${SERVER_URL}/stripe/payment-info/${token}`;
      fetch(url, {
        method: "GEt",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: app.token,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          console.log("response", response);
          if (response && response.email) setUserinfo(response);
        });
    }
  }, []);

  return (
    <>
      {userInfo && token && <Payment userInfo={userInfo} />}
      {succeed && (
        <>
          <SuccessPayment />
          <div className="goBackApp">
            <div>You can go back to mobile applicaiton</div>
            <div>Du kan gå tillbaka till mobilapplikationen.</div>
            <div>يمكنك العودة إلى التطبيق المحمول</div>
          </div>
        </>
      )}
    </>
  );
};

export default AppPaymentPage;

import React, { useState, useEffect } from "react";
import "./translator-result.styles.scss";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { stripH2Tag } from "../../utils";
const TranslatorResult = () => {
  const searchResult = useSelector((state) => state.translator);
  const [displayResult, setDisplayResult] = useState([]);
  useEffect(() => {
    setDisplayResult(searchResult.searchResult);
  }, [searchResult]);
  return (
    <div className="SearchhResult">
      <div className="SearchResult_header">
        <span className="se se-bold">sökresultat</span>
        <span className="ar ar-bold">نتيجة البحث</span>
      </div>
      <div className="resultTable">
        {!displayResult && displayResult.length === 0 && (
          <div className="resultDivs">
            <div className="swedishRs resultDiv">Inga resultat</div>
            <div className="englishRs resultDiv">No Results</div>
            <div className="arabicRs resultDiv">لا يوجد نتائج</div>
          </div>
        )}
        {displayResult && displayResult.length > 0 && (
          <>
            <div className="headerDiv">
              <div className="headerResul swedishHeaderRs">Swedish</div>
              <div className="headerResul englishHeaderRs">English</div>
              <div className="headerResul arabicHeaderRs">عربي</div>
            </div>

            {displayResult.map((element, index) => {
              return (
                <div className="resultDivs" key={`resultData_${index}`}>
                  <div
                    className="swedishRs resultDiv"
                    dangerouslySetInnerHTML={{
                      __html: element.swedish,
                    }}
                  ></div>
                  <div
                    className="englishRs resultDiv"
                    dangerouslySetInnerHTML={{
                      __html: element.english,
                    }}
                  ></div>
                  <div
                    className="arabicRs resultDiv"
                    dangerouslySetInnerHTML={{
                      __html: element.arabic,
                    }}
                  ></div>
                </div>
              );
            })}
          </>
        )}
        <div>
          <span className="copy">* Copyright 2020, Hussam Alkoblawi</span>
        </div>
      </div>
      {/* {searchResult.searchResult ? (
        <div
          className={
            "ResultBox " +
            searchResult.searchResult_lang +
            " " +
            searchResult.searchResult_lang +
            "-bold"
          }
          dangerouslySetInnerHTML={{
            __html: searchResult.searchResult
              .replace(/\n/g, "<br/>")
              .slice(0, -2),
          }}
        ></div>
      ) : (
        <div className="ResultBox">
          <div>نتيجة البحث - sökresultat</div>
        </div>
      )} */}
    </div>
  );
};

export default TranslatorResult;

import React, { useState, useEffect } from "react";
import "./swedish-to-arabic.styles.scss";
import Autocomplete from "react-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  SetSearchResult,
  clearResults,
} from "../../redux/translator/translator.actions";
import { insertToken } from "../../redux/app/app.actions";
import { cleanText } from "../../utils/index";
import { addOpening } from "../../redux/app/app.actions";
import { SERVER_URL } from "../../constants";
const SwedishToArabic = () => {
  const app = useSelector((state) => state.app);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(SetSearchResult(""));
    setSuggestions([]);
    if (value.length > 0) {
      const timer = setTimeout(() => {
        let url = `${SERVER_URL}/phrases/search/startWith/swedish/${value}`;
        fetch(url, {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            Authorization: app.token,
            TrailPeriod: app.openings,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              // Explicitly handle HTTP errors
              setError("Your not authorized, please login");
              dispatch(insertToken(""));
              return;
            }
            return res.json();
          })
          .then((response) => {
            setIsOpen(true);
            if (response && response.statusCode != 401) {
              dispatch(addOpening());
              let newsuggestions = [];

              let filteredResults = response.filter((val) =>
                String(
                  val.swedish
                    .replace(/<[^>]+>/g, "")
                    .toString()
                    .toLowerCase()
                ).startsWith(value.toString().toLowerCase())
              );
              filteredResults.map((element) => {
                newsuggestions.push({
                  label: cleanText(element.swedish),
                  id: element.id,
                });
              });
              setSuggestions(newsuggestions);
            }
          })
          .catch((error) => {
            setError("Your not authorized, please login");
            console.log("error", error);
          });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [value]);

  const search = (searchText) => {
    let id = searchText.substr(0, searchText.indexOf("*"));
    let newSearchtext = searchText.substr(searchText.indexOf("*") + 1);
    setValue(newSearchtext);
    fetch(`${SERVER_URL}/phrases/${id}`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Authorization: app.token,
        TiralPeriod: app.openings,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          // Explicitly handle HTTP errors
          setError("Your not authorized, please login");
          dispatch(insertToken(""));
          return;
        }
        return res.json();
      })
      .then((response) => {
        setIsOpen(false);
        dispatch(SetSearchResult(response));
      });
  };

  const clearText = () => {
    setValue("");
    setIsOpen(false);
    dispatch(clearResults());
  };
  const searchByButton = () => {
    if (value) {
      const query = encodeURIComponent(value);
      fetch(`${SERVER_URL}/phrases/exact-phrase/swedish/${query}`, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          Authorization: app.token,
          TiralPeriod: app.openings,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          setIsOpen(false);
          if (response.length > 0) {
            dispatch(SetSearchResult(response));
          }
        });
    }
  };

  /*   useEffect(() => {
    if (isOpen === true) {
      setTimeout(() => {
        setIsOpen(false);
      }, 15000);
    }
  }, [isOpen]); */

  return (
    <div className="swedishToarabic">
      {error && <div className="error">{error}</div>}
      <div className="autocomplete">
        <button className="searchBtn" onClick={searchByButton}>
          Sök
        </button>

        <Autocomplete
          getItemValue={(item) => item.id + "*" + item.label}
          items={suggestions}
          renderItem={(item, isHighlighted) => {
            const index = suggestions.findIndex(
              (suggestion) => suggestion.id === item.id
            );
            const oddEvenClass = index % 2 === 0 ? "even" : "odd";
            const styledLabel = item.label.replace(
              value,
              `<span class="bold">${value}</span>`
            );
            return (
              <div
                key={"autocomplete_swedish_" + Math.random()}
                className={`se se-light insideautocomplete ${oddEvenClass}`}
                dangerouslySetInnerHTML={{ __html: styledLabel }}
              ></div>
            );
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onSelect={(val) => search(val)}
          key="field_1002"
          inputProps={{
            placeholder: "för avancerad sökning och bredare resultat tryck SÖK",
          }}
        />
        {value.length > 0 && (
          <button className="closeBtn" onClick={clearText}>
            Radera text
          </button>
        )}
      </div>
    </div>
  );
};

export default SwedishToArabic;

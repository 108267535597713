import React, { useState } from "react";
import "./login.styles.scss";
import { Container } from "reactstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { insertToken } from "../../redux/app/app.actions";
import { SERVER_URL } from "../../constants";
function Login() {
  const app = useSelector((state) => state.app);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (values) => {
    if (values.password !== values.confirmPassword) {
      setError(`Password didn't match / كلمة المرور غير متطابقة`);
    }
    delete values.confirmPassword;
    // const jsonValues = JSON.stringify(values);
    fetch(`${SERVER_URL}/auth/login`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("login response", response);
        if (response) {
          if (response.access_token) {
            dispatch(insertToken(response.access_token));
            window.location.reload();
          } else {
            setError(`wrong`);
          }
        }
      })
      .catch((err) => {
        setError(`Email of Password is not correct.`);
        console.error("Error:", err);
      });
  };
  return (
    <div className="Login">
      <Container>
        <>
          <div
            className="askDescription"
            style={{ textAlign: "right", marginBottom: 30 }}
          >
            Please Login / الرجاء تسجيل الدخول{" "}
          </div>
          <div className="questionForm">
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                flexWrap: "wrap",
              }}
            >
              <input
                name="email"
                className="InputEmail Input"
                placeholder="البريد الالكتروني"
                required
                {...register("email")}
                style={{
                  width: "49%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 5,
                  height: "50px",
                }}
              />
              <input
                name="password"
                className="InputPassword Input"
                placeholder="كلمة السر / Password"
                required
                {...register("password")}
                type="password"
                style={{
                  width: "30%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 10,
                }}
              />
              <div className="error">{error && error}</div>
              <div className="note">
                We process your personal data in accordance with the General
                Data Protection Regulation (GDPR)
              </div>
              <div className="SubmitComment" style={{ marginTop: 30 }}>
                <Button>Login - تسجيل الدخول</Button>
              </div>
            </form>
          </div>
        </>
      </Container>
    </div>
  );
}

export default Login;

import React, { useState, useRef, useEffect } from "react";
import "./contact-us-form.styles.scss";
import { Button, Container } from "reactstrap";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import TickIcon from "../../assets/tick.png";
import { SERVER_URL } from "../../constants";
const ContactUsForm = () => {
  const app = useSelector((state) => state.app);
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    const jsonValues = JSON.stringify(values);
    fetch(`${SERVER_URL}/contactus`, {
      method: "POST",
      body: jsonValues,
      headers: {
        Authorization: app.token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      setSubmitted(true);
    });
  };

  return (
    <div className="contactUsForm">
      <Container>
        <div className="contactHeader">
          <div>Skicka ett meddelande till oss</div>
          <div>Send us a message</div>
          <div>ارسل رسالة</div>
        </div>

        <div className="questionForm">
          {submitted ? (
            <div className="contactSubmitted">
              <div className="tickIcon">
                {" "}
                <img src={TickIcon} />{" "}
              </div>
              <div className="submittedMessage">تم استلام رسالتك بنجاح</div>
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <input
                name="name"
                className="InputName Input"
                placeholder="الاسم"
                required
                {...register("name")}
                style={{
                  width: "100%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 10,
                }}
              />
              <br />
              <input
                name="email"
                className="InputEmail Input"
                placeholder="البريد الالكتروني"
                required
                {...register("email")}
                style={{
                  width: "100%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 10,
                }}
              />
              <br />

              <textarea
                id="description"
                {...register("message")}
                required
                className="InputMessage Input"
                placeholder="الرسالة"
                style={{
                  width: "100%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  padding: 10,
                  borderRadius: 10,
                }}
              />

              <div className="SubmitComment" style={{ marginTop: 30 }}>
                <Button>Send - ارسل</Button>
              </div>
            </form>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ContactUsForm;

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appReducer from "./app/app.reducer";
import translatorReducer from "./translator/translator.reducer";
import AsyncStorage from "@react-native-async-storage/async-storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["app"],
  migrate: async (state) => {
    const isFirstLaunch = await AsyncStorage.getItem("firstTimeLaunch");
    if (!isFirstLaunch) {
      await AsyncStorage.setItem("firstTimeLaunch", "true");
      return {
        ...state,
        app: {
          ...state.app,
          translationType: "SWEDISH",
          openings: 0,
        },
      };
    }
    return state;
  },
};

const rootReducer = combineReducers({
  app: appReducer,
  translator: translatorReducer,
});

export default persistReducer(persistConfig, rootReducer);

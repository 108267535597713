import React from "react";
import "./language-tabs.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { TranslationType } from "../../redux/translationType.enum";
import { setTranslationType } from "../../redux/app/app.actions";
import { Container } from "reactstrap";
function LanguageTabs() {
  const dispatch = useDispatch();
  const translationType = useSelector((state) => state.app.translationType);
  return (
    <div className="LanguageTabs">
      <Container className="LanguageTabsContainer">
        <div className="tabs">
          <div className="tab">Välj översättningsspråk</div>
          <div
            onClick={() =>
              dispatch(setTranslationType(TranslationType.SWEDISH))
            }
            className={
              translationType === TranslationType.SWEDISH
                ? "tab pointer active"
                : "tab pointer"
            }
          >
            Från svenska
          </div>
          <div
            onClick={() =>
              dispatch(setTranslationType(TranslationType.ENGLISH))
            }
            className={
              translationType === TranslationType.ENGLISH
                ? "tab pointer active"
                : "tab pointer"
            }
          >
            From English{" "}
          </div>
          <div
            onClick={() => dispatch(setTranslationType(TranslationType.ARABIC))}
            className={
              translationType === TranslationType.ARABIC
                ? "tab pointer active"
                : "tab pointer"
            }
          >
            من العربية
          </div>
          <div className="tab">اختر لغة الترجمة</div>
        </div>
      </Container>
    </div>
  );
}

export default LanguageTabs;

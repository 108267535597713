import React, { useState, useEffect } from "react";
import "./add-comment.styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import { useSelector } from "react-redux";
import InfoSection from "../info-section/info-section.component";
import { useForm } from "react-hook-form";
import { SERVER_URL } from "../../constants";
const AddComment = () => {
  const [showAddComment, SetShowAddComment] = useState(true);

  const app = useSelector((state) => state.app);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    const jsonValues = JSON.stringify(values);
    fetch(`${SERVER_URL}/comments`, {
      method: "POST",
      body: jsonValues,
      headers: {
        Authorization: app.token,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        SetShowAddComment(false);
      });
  };

  return (
    <div className="AddComment">
      <Container>
        <Row>
          <Col>
            {showAddComment ? (
              <div>
                <InfoSection
                  arabic="اضف تعليق"
                  swedish="Lägg till en kommentar"
                />
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <textarea
                    name="body"
                    className="WriteComment"
                    placeholder="اكتب تعليقا - Lägg till en kommentar"
                    required
                    {...register("body")}
                    style={{
                      width: "100%",
                      margin: "0px 5px",
                      textAlign: "right",
                      direction: "rtl",
                      border: "1px solid #373737",
                      borderRadius: 10,
                      padding: 10,
                    }}
                  />
                  <br />
                  <input
                    name="writer"
                    {...register("writer")}
                    className="WriteComment"
                    placeholder="الاسم - Namnet"
                    required
                    style={{
                      width: "100%",
                      margin: "0px 5px",
                      textAlign: "right",
                      direction: "rtl",
                      border: "1px solid #373737",
                      borderRadius: 10,
                      padding: 10,
                    }}
                  />

                  <div className="SubmitComment">
                    <Button>Send - ارسل</Button>
                  </div>
                </form>
              </div>
            ) : (
              <Row className="Thankyou">
                <Col className="se se-bold">Tack</Col>
                <Col className="ar ar-bold">شكرا لك</Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddComment;

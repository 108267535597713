import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import { Container, Row, Col, Button } from "reactstrap";
import "./account.style.scss";
import AccountInfo from "../../components/account-info/account-info.component";
import SubscriptionInfo from "../../components/subscription-info/subscription-info.component";
import { useSelector, useDispatch } from "react-redux";
import CancelSubscription from "../../components/cancel-subscription/cancel-subscription.component";
import LoginRegisterPasswordTabs from "../../components/login-register-forgotpassword-tabs/tabs.component";
import Payment from "../../components/payment/payment.component";
import { SERVER_URL } from "../../constants";
import { logOut } from "../../redux/app/app.actions";
const AccountPage = () => {
  const app = useSelector((state) => state.app);
  const [userInfo, setUserInfo] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (app.token && app.token !== "") {
      const timer = setTimeout(() => {
        let url = `${SERVER_URL}/users/user-profile`;
        fetch(url, {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            Authorization: app.token,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            console.log("user data", response[0]);
            setUserInfo(response[0]);
          });
      });
      return () => clearTimeout(timer);
    }
  }, []);

  const logout = () => {
    dispatch(logOut());
    window.location.href = "/";
  };

  if (!app.token || app.token === "") {
    return (
      <div className="accountPage">
        <Header />
        <Container className="accountContainer">
          <LoginRegisterPasswordTabs />
        </Container>
      </div>
    );
  }

  return (
    <div className="accountPage">
      <Header />
      <Container className="accountContainer">
        <div className="accountHeader">
          <h1>Mitt konto</h1>
          <h1>Account Page</h1>
          <h1>حسابي</h1>
        </div>
        <div className="accountBody">
          <div className="accountInfo">
            <AccountInfo data={userInfo} />
          </div>
          <div className="accountSubscription">
            {userInfo.paid ? (
              <>
                {" "}
                <SubscriptionInfo data={userInfo} />
                <CancelSubscription />
              </>
            ) : (
              <Payment appUserInfo={null} />
            )}
          </div>
        </div>
        <Button onClick={() => logout()} className="logoutButton">
          Logout
        </Button>
      </Container>
      <Footer />
    </div>
  );
};

export default AccountPage;

import React from "react";
import "./subscription-info.styles.scss";
import { Container } from "reactstrap";
import Payment from "../payment/payment.component";
import { extractDate } from "../../utils";
import ActiveImage from "../../assets/active.png";
const SubscriptionInfo = (data) => {
  const userInfo = data.data;
  console.log("userInfo", userInfo);

  return (
    <div className="SubscriptionBigBiox">
      <Container>
        <h2>Subscriptions - الاشتراك</h2>
        <div className="SubscriptionPaymentBox">
          {userInfo.paid ? (
            <div className="subscriptionBox">
              <div className="subscriptionStatus">
                <div>Subscription is Active</div>
                <div className="center">
                  <img src={ActiveImage} className="activeImage" />
                </div>
                <div>الاشتراك مفعل</div>
              </div>
              <div className="subscriptionStatus">
                <div>Expires on:</div>
                <div className="greenBox">
                  {extractDate(userInfo.subscriptionExpiry)}
                </div>
                <div>:صالح لغاية</div>
              </div>
            </div>
          ) : (
            <Payment />
          )}
        </div>
      </Container>
    </div>
  );
};

export default SubscriptionInfo;

import React, { useState, useRef, useEffect } from "react";
import "./cancel-subscription.styles.scss";
import QuestionIcon from "../../assets/whoweare.jpg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import TickIcon from "../../assets/tick.png";
import { SERVER_URL } from "../../constants";
const CancelSubscription = () => {
  const app = useSelector((state) => state.app);
  const [modal, setModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const toggle = () => setModal(!modal);
  console.log("app", app.user_profile);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    app.user_profile.cancellation_request = values.message;
    fetch(`${SERVER_URL}/users/cancellation-request`, {
      method: "POST",
      body: JSON.stringify({
        user: app.user_profile,
      }),
      headers: new Headers({
        "Content-Type": "application/json", // Add this
        Accept: "application/json",
      }),
    }).then((response) => {
      setSubmitted(true);
    });
  };

  return (
    <div className="cancelSubscription">
      <div
        className="button"
        onClick={toggle}
        className="cancelSubscriptionBtn"
      >
        <span>Avsluta prenumeration</span>
        <span>Cancel Subscription</span>
        <span>طلب الغاء الاشتراك</span>
      </div>
      <Modal isOpen={modal} toggle={toggle} className="ModalBox modal-lg">
        <ModalHeader style={{ textAlign: "right", direction: "rtl" }}>
          طلب الغاء الاشتراك
        </ModalHeader>
        <ModalBody>
          {submitted && (
            <div style={{ textAlign: "center" }}>
              <img
                src={TickIcon}
                style={{ width: "100px", marginTop: 30, marginBottom: 30 }}
              />
              <p style={{ width: "100%", marginTop: 30, marginBottom: 30 }}>
                شكرا لك، سوف نقوم بالرد على سؤالك في اقرب وقت.
              </p>
            </div>
          )}
          {!submitted && (
            <>
              <div
                className="askDescription"
                style={{
                  textAlign: "right",
                  marginBottom: 30,
                  direction: "rtl",
                }}
              >
                في حال رغبتك بإلغاء اشتراكك، يرجى تعبئة النموذج أدناه وسنقوم
                بالغاءالاشتراك مع بداية مدة الدفع الجديد. قد تستغرق عملية
                الإلغاء ما يصل إلى أسبوع.
              </div>
              <div className="questionForm">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <textarea
                    id="description"
                    {...register("message")}
                    required
                    className="InputMessage Input"
                    placeholder="Anledning till avbokning |  Reason for cancellation |   سبب الإلغاء"
                    style={{
                      width: "100%",
                      margin: "0px 5px",
                      textAlign: "right",
                      direction: "rtl",
                      border: "1px solid #373737",
                      padding: 10,
                      borderRadius: 10,
                    }}
                  />

                  <div className="SubmitComment" style={{ marginTop: 30 }}>
                    <Button>Request | ارسال الطلب</Button>
                  </div>
                </form>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CancelSubscription;

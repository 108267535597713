import React, { useState, useEffect } from "react";
import { loadStripe, StripeCheckout } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout-form"; // Import your checkout form
import { useDispatch, useSelector } from "react-redux";

// Load your Stripe public key
const stripePromise = loadStripe("pk_test_hG9CyIgEejiAG8Xy8RMEuVX700eJmnBPm4"); // Replace with your own key

const Checkout = () => {
  const [paymentSession, setPaymentSession] = useState();
  const app = useSelector((state) => state.app);

  useEffect(() => {
    // if(!paymentSession){
    //   fetch(`${SERVER_URL}/stripe/payment-session`, {
    //     method: "GET",
    //     headers: new Headers({
    //       Accept: "application/json",
    //       Authorization: app.token,
    //     }),
    //   })
    //     .then((res) => res.json())
    //     .then((response) => {
    //       // window.location.href = response.url;
    //     });
    // }
  }, []);

  return "";
};

export default Checkout;

import React, { useState, useEffect } from "react";
import Header from "../../components/header/header.component";
import Introduction from "../../components/introduction/introduction.component";
import Translate from "../../components/translate-box/translate-box.component";
import { Element } from "react-scroll";
import LanguageTabs from "../../components/language-tabs/language-tabs.component";
import Mobile_Box from "../../components/mobile_box/mobile_box.component";
import InfoSection from "../../components/info-section/info-section.component";
import Boxes from "../../components/boxes/boxes.component";
import AboutUs from "../../components/about-us/about-us.component";
import Books from "../../components/books/books.component";
import ContactUs from "../../components/contact-us/contact-us.component";
import Footer from "../../components/footer/footer.component";
import UsedTermsCategories from "../../components/used-terms-categories/used-terms-categories.component";
import Testemonials from "../../components/testemonials/testemonials.component";
import { useDispatch, useSelector } from "react-redux";
import "./home.styles.scss";
import { Row, Col, Button } from "reactstrap";
import { useLocation } from "react-router-dom";
import Payment from "../../components/payment/payment.component";
import { setUserProfile } from "../../redux/app/app.actions";
import ThisDictionary from "../../components/dictionary/dictionary.component";
import ContactUsForm from "../../components/contact-us-form/contact-us-form.component";
import SuccessPayment from "../../components/success-payment/success-payment.component";
import Modal from "react-modal";
import LoginRegisterPasswordTabs from "../../components/login-register-forgotpassword-tabs/tabs.component";
import Popup from "../../components/popup/popup.component";
import { SERVER_URL } from "../../constants";
const HomePage = () => {
  const app = useSelector((state) => state.app);
  const [opentab, setOpenTab] = useState("register");
  const [showPayment, setShowPayment] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [userSubscribed, setUserSubscription] = useState(true);
  const [successPayment, setSuccessPayment] = useState(() => {
    if (searchParams.get("status") === "success") return true;
    else return false;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (app.token && app.token !== "") {
      const timer = setTimeout(() => {
        let url = `${SERVER_URL}/users/user-profile`;
        fetch(url, {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            Authorization: app.token,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            console.log("user data", response[0]);
            if (response[0]) {
              setUserSubscription(response[0].paid);
              dispatch(setUserProfile(response[0]));
              if (response[0].paid === false) {
                setShowPayment(true);
              }
            }
          });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const closeModal = () => {
    setSuccessPayment(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div className="App">
      <Popup />
      <Header />
      <Modal
        isOpen={successPayment}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Thank you"
      >
        {" "}
        <Button onClick={closeModal}>close</Button>
        <SuccessPayment />
      </Modal>

      {app.openings >= 10 && app.token === "" ? (
        <LoginRegisterPasswordTabs />
      ) : (
        <Element name="translator" className="element">
          <Introduction />
          {userSubscribed || app.openings <= 25 ? (
            <>
              <LanguageTabs />
              <Translate />
            </>
          ) : (
            <Payment appUserInfo={null} />
          )}
        </Element>
      )}
      <br />
      <br />

      {/*  <InfoSection
          arabic="معلومات عن القاموس"
          swedish="Information om ordboken"
        /> */}
      <Element name="usedterms" className="usedterms">
        <InfoSection
          arabic="عبارات و مصطلحات شائعة الاستعمال"
          swedish="Användbara fraser och facktermer"
        />
        <UsedTermsCategories />
      </Element>
      <br />
      <br />
      <Element name="testimonials" className="testimonials">
        <Testemonials />
      </Element>
      <Element className="kofeyaback"></Element>

      <Element name="ThisDictionary" className="ThisDictionary">
        <ThisDictionary />
      </Element>
      <Element className="kofeyaback"></Element>
      <Element name="aboutus" className="element">
        <AboutUs />
      </Element>
      <Element className="kofeyaback"></Element>
      <Element name="books" className="element">
        <InfoSection arabic="الكتب" swedish="Böcker" />
        <Books />
      </Element>
      <Element className="kofeyaback"></Element>

      <Element>
        <InfoSection arabic="حمل التطبيق" swedish="Ladda ner applikation" />
        <Mobile_Box />
      </Element>
      <Boxes />
      <Element className="kofeyaback"></Element>
      <Element name="contactus" className="element">
        <InfoSection arabic="تواصل معنا" swedish="Kontakta oss" />
        <Row>
          <Col xs={12} md={2}></Col>
          <Col xs={12} md={6}>
            <ContactUsForm />
          </Col>
          <Col xs={12} md={2}>
            <ContactUs />
          </Col>
        </Row>
      </Element>
      <Footer />
      {/* <AskDoctor /> */}
    </div>
  );
};

export default HomePage;

export const ARData = `    
<br/>
<br/> 
        <h2 style="text-align:right">من نحن</h2>
<br/>
<br/>
<div className="aboutUs_sec_2 ar ar-light" style="text-align: right;">
              <h3>المؤلف</h3>
<br/>
<br/>
حسام القبلاوي فلسطيني األصل، لبناني المولد سويدي الجنسية. ولد في بيروت
لؤلؤة الشرق حيث أنهى دراسته المدرسية في مدرسة الفاروق عمر ابن الخطاب
التابعة لجمعية المقاصد اإلسالمية
              <br />
              <br />
            </div>
            <div className="aboutUs_sec_2 ar ar-light" style="text-align: right;">
.حاصل على مؤهالت علمية عُليا من القاهرة والسويد
عمل مدرساً وباحثاً في معهد علوم األرض، كلية العلوم، جامعة لوند. عمل أيضاً
مدرسا لمادة الترجمة في كلية اآلداب واللغات، جامعة لوند ومحاضراً في قانون ً
المدارس للوافدين ال ُجدد من المعلمين في كلية ال ُمعلمين جامعة مالمو. في العام
1992 أصبح أول مترجماًمحلفاً بين السويدية والعربية في منطقة جنوب السويد
مترجما محلفاًمتخصصاً في القانون ويعمل مدرساً للترجمة ً ليصبح فيما بعد
ومحاضرا في قضايا المجتمع وتالقي الحضارات. أصدر العديد من القواميس ً
والكتب المتخصصة والروايات
            
            </div>`;

export const SEData = `   
<br/>
<br/>
              <h2>Om oss</h2>
<br/>
<br/>
              <div className="aboutUs_sec_2 ar ar-light">
  <h3>Författaren:</h3>
<br/>
<br/>
              Fil.dr. Hussam Alkoblawi är svensk medborgare med palestinskt
ursprung, född i Libanon. Han har hög akademisk utbildning från
både Sverige och i Egypten. Han arbetade som forskare och hade
doktorandtjänst vid geologiska institution, Lunds universitet. Han
arbetade även som språkpedagog vid litteratur- och
språkcentrum, Lunds universitet, och som föreläsare i skollagen
för nyanlända med utländsk lärarutbildning, Malmö Universitet.
              <br />
              <br />
            </div>
            <div className="aboutUs_sec_2 ar ar-light" >
              1992 blev han Skånes första auktoriserade tolk mellan svenska
och arabiska, och numera är han rättstolk, tolklärare,
språkhandledare och föreläsare inom ämnena integration och
kultur. Alkoblawi har tidigare gett ut fem ordböcker. Han har även
gett ut andra fackböcker och romaner.
            </div>`;

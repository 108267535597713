export const englishData = [
  `In order to continue using this unique dictionary, you must subscribe to the service. The subscription is not binding beyond the period you choose and can be canceled at any time.`,
  `The subscription gives you access to use the service on the web or on the mobile application.`,
  `Secure payment with Stripe.`,
  `There are two types of subscriptions. Monthly subscription or annual subscription.`,
  `The subscription is renewed and charged automatically when the contract period (month or year) has expired and if no cancellation is made.`,
  `You can cancel your subscription before the next payment period. Cancellation must be made no later than the day before a new payment period begins.`,
  `You can change your subscription from monthly to annual subscription the day before a new payment period begins.`,
  `You can cancel your subscription before the next period but it is not possible to end a subscription early. The subscription and your access continue until the end of the contract period. No refunds are made.`,
  `There are no other hidden fees or conditions. All you need to do is pay the fee for the subscription period you have chosen. The service will then be activated.`,
];

export const sewdishData = [
  `För att kunna fortsätta använda denna unika ordbok måste du prenumerera på tjänsten.  Prenumerationen är inte bindande utöver den perioden du väljer och kan avbrytas när som helst.`,
  `Prenumerationen ger dig tillgång att använda tjänsten på webben eller på mobilapplikationen `,
  `Trygg betalning med Stripe.`,
  `Det finns två typer av prenumerationer. Månadsprenumeration eller årsprenumeration.`,
  `Prenumerationen förnyas och debiteras automatiskt när avtalstiden (månad eller år) har löpt ut och om ingen uppsägning gjort.`,
  `Du kan säga upp din prenumeration inför nästa betalningsperiod. Uppsägning måste ske senast dagen innan en ny betalningsperiod påbörjas.`,
  `Du kan ändra din prenumeration från månadsvis till årsprenumeration dagen innan en ny betalningsperiod påbörjas.`,
  `Du kan säga upp din prenumeration inför nästkommande period men det går inte att avsluta en prenumeration i förtid. Prenumerationen och din åtkomst fortlöper tills avtalstiden är slut. Inga återbetalningar görs.`,
  `Det finns inga andra dolda avgifter eller villkor. Allt du behöver göra är att betala avgiften för den prenumerationsperiod du har valt. Därefter aktiveras tjänsten.`,
];

export const arabicData = [
  `للاستمرار في استخدام هذا القاموس الفريد، يجب الاشتراك في الخدمة. الاشتراك غير ملزم بعد الفترة التي تختارها ويمكن إلغاؤه في أي وقت.`,
  `يمنحك الاشتراك إمكانية الوصول إلى استخدام الخدمة على الويب أو على تطبيق الهاتف المحمول.`,
  `دفع آمن باستخدام Stripe.`,
  `هناك نوعان من الاشتراكات: الاشتراك الشهري أو الاشتراك السنوي.`,
  `يتم تجديد الاشتراك وخصم الرسوم تلقائيًا عند انتهاء فترة العقد (شهر أو سنة) إذا لم يتم الإلغاء.`,
  `يمكنك إلغاء اشتراكك قبل فترة الدفع التالية. يجب أن يتم الإلغاء في موعد أقصاه اليوم الذي يسبق بدء فترة دفع جديدة.`,
  `يمكنك تغيير اشتراكك من اشتراك شهري إلى اشتراك سنوي قبل يوم واحد من بدء فترة الدفع الجديدة.`,
  `يمكنك إلغاء اشتراكك قبل الفترة التالية، ولكن لا يمكن إنهاء الاشتراك مبكرًا. يستمر الاشتراك ووصولك إلى الخدمة حتى نهاية فترة العقد. لا يتم تقديم أي استرداد مالي.`,
  `لا توجد أي رسوم أو شروط مخفية أخرى. كل ما عليك فعله هو دفع الرسوم للفترة التي اخترتها للاشتراك. سيتم بعد ذلك تفعيل الخدمة.`,
];

export const detailsMonthly = {
  EN: `Subscription automatically renews and is charged monthly and can be canceled one day before the start of a new monthly period. `,
  SE: `Prenumerationen förnyas och debiteras automatiskt månadsvis och kan sägas upp en dag innan en ny månadsperiod påbörjas.`,
  AR: `يتم تجديد الاشتراك تلقائيًا ويتم خصم الرسوم شهريًا، ويمكن إلغاؤه قبل يوم واحد من بدء فترة شهرية جديدة.`,
};

export const detailsYearly = {
  EN: `Subscription automatically renews and is charged annually and can be canceled one day before the start of a new annual period.  `,
  SE: `Prenumerationen förnyas och debiteras automatiskt årsvis och kan sägas upp en dag innan en ny årsperiod påbörjas.`,
  AR: `يتم تجديد الاشتراك تلقائيًا ويتم خصم الرسوم سنويًا، ويمكن إلغاؤه قبل يوم واحد من بدء فترة سنوية جديدة.`,
};

export const youSave = {
  EN: `You save`,
  SE: `Du sparar`,
  AR: `سوف توفر`,
};

export const payNow = {
  EN: `Pay now`,
  SE: `Betala nu`,
  AR: `ادفع الآن`,
};

export const stripeText = {
  EN: `Payment process is done with stripe in a secure environment and we dont store any information regarding your payment method`,
  SE: `Betalningsprocessen sker med Stripe i en säker miljö, och vi lagrar ingen information om din betalningsmetod.`,
  AR: `يتم تنفيذ عملية الدفع باستخدام سترايب في بيئة آمنة، ولا نقوم بتخزين أي معلومات تتعلق بوسيلة الدفع الخاصة بك.`,
};

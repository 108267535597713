export const cleanText = (text) => {
  let result = text.replace(/<[^>]+>/g, "");
  result = result.replace(/&nbsp;/g, "");
  result = result.replaceAll("<p>", "").replaceAll("</p>", `, `);
  return result;
};

export const stripHtmlTags = (html) => {
  if (html) {
    return html.replace(/<(?!\/?p(?=>|\s.*>))\/?.*?>/g, "");
  } else {
    return "";
  }
};

export const stripH2Tag = (html) => {
  if (html) {
    return html.replace(/<h\d.*?>(.*?)<\/h\d>/gi, "$1");
  } else {
    return "";
  }
};

export const stripAllHtmlTags = (html) => {
  let result = html.replace(/<\/?[^>]+(>|$)/g, " ");
  result = result.replace(/&nbsp;/g, " ");
  return result;
};

export const extractDate = (isoString) => {
  const date = new Date(isoString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

import React, { useState } from "react";
import "./success-payment.styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import TickIcon from "../../assets/tick.png";
const SuccessPayment = () => {
  return (
    <div className="successPayment" classID>
      <Container>
        <Row>
          <Col md="2">
            <img src={TickIcon} className="tickIcon" />
          </Col>
          <Col>
            <Row>
              <Col sm={12}>
                <h1>Payment successful</h1>
                <h2>Thank you</h2>
              </Col>
              <Col className="ar" sm={12}>
                <h1>تم الدفع بنجاح</h1>
                <h2>شكرا لك</h2>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SuccessPayment;

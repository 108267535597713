import React, { useState, useEffect } from "react";
import "./translate.styles.scss";
import { Container, Row, Col } from "reactstrap";
import ArabicToSwedish from "../arabic-to-swedish/arabic-to-swedish.component";
import SwedishToArabic from "../swedish-to-arabic/swedish-to-arabic.component";
import TranslatorResult from "../translator-result/translator-result.component";
import EnglishTranslationBox from "../english-box/english-box.component";
import TranslateHeader from "../translate-header/translate-header.component";
import { TranslationType } from "../../redux/translationType.enum";
import { useSelector } from "react-redux";

const Translate = () => {
  const app = useSelector((state) => state.app);
  return (
    <div className="translateContainer">
      <Container className="translateContainer">
        <Row>
          <Col xs={12} md={12}>
            <TranslateHeader />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            {(app.translationType === TranslationType.SWEDISH ||
              app.translationType === "SWEDISH_ARABIC") && <SwedishToArabic />}
            {app.translationType === TranslationType.ENGLISH && (
              <EnglishTranslationBox />
            )}

            {app.translationType === TranslationType.ARABIC && (
              <ArabicToSwedish />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <TranslatorResult />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Translate;

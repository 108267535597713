import React, { useState, useEffect } from "react";
import "./payment.styles.scss";
import { Container, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  arabicData,
  englishData,
  sewdishData,
  detailsMonthly,
  detailsYearly,
  youSave,
  stripeText,
  payNow,
} from "./data";
import StripeLogo from "../../assets/Stripe-paiement.png";
import { SERVER_URL } from "../../constants";

function Payment(appUserInfo) {
  console.log("in payment");
  const app = useSelector((state) => state.app);
  const [language, setLanguage] = useState("SE");
  const pay = (period) => {
    let userInfo = {};
    if (appUserInfo && appUserInfo.userInfo != null) {
      userInfo = {
        id: appUserInfo.userInfo._id,
        email: appUserInfo.userInfo.email,
        period: period,
        source: "app",
      };
    } else {
      userInfo = {
        id: app.user_profile._id,
        email: app.user_profile.email,
        period: period,
        source: "website",
      };
    }

    console.log("userInfo 2", userInfo);
    fetch(`${SERVER_URL}/stripe/payment-session`, {
      method: "POST",
      body: JSON.stringify(userInfo),
      headers: {
        "Content-Type": "application/json",
        Authorization: app.token,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response && response.url) {
          window.location.href = response.url;
        }
      });
  };
  return (
    <div className="Payment">
      <Container>
        <>
          <br />
          {!appUserInfo ? (
            <h1>Hello, {app.user_profile.fullName} ،مرحبا</h1>
          ) : (
            <h1>Hello مرحبا</h1>
          )}
          <br />
          <div className="language">
            <button
              onClick={() => setLanguage("SE")}
              className={language === "SE" ? "active" : ""}
            >
              SE
            </button>
            <button
              onClick={() => setLanguage("EN")}
              className={language === "EN" ? "active" : ""}
            >
              EN
            </button>
            <button
              onClick={() => setLanguage("AR")}
              className={language === "AR" ? "active" : ""}
            >
              AR
            </button>
          </div>
          <br />
          <div className="stripePayment">
            <img src={StripeLogo} className="stripeLogo" />
            <div className="stripeText">{stripeText[language]}</div>
          </div>
          {language === "SE" && (
            <ul className="list">
              {sewdishData.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          )}
          {language === "AR" && (
            <ul className="list list-ar">
              {arabicData.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          )}
          {language === "EN" && (
            <ul className="list ">
              {englishData.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          )}
          <div className="paymentOptions">
            <div className="payOption monthly">
              <label>Monthly - شهري</label>
              <div className="subscriptionDetails">
                <div className="price">99 Svenska kroner</div>
                <div className="details">{detailsMonthly[language]}</div>
              </div>
              <div className="payNow">
                <Button onClick={() => pay("monthly")}>
                  {payNow[language]}
                </Button>
              </div>
            </div>
            <div className="payOption yearly">
              <label>Yearly - سنوي</label>
              <div className="subscriptionDetails">
                <div className="discountBox">
                  <div className="youSave">{youSave[language]}</div>
                  <div className="discount"> 198 Svenska kroner</div>
                </div>

                <div className="price">990 Svenska kroner</div>
                <div className="details">{detailsYearly[language]}</div>
              </div>
              <div className="payNow">
                <Button onClick={() => pay("yearly")}>
                  {payNow[language]}
                </Button>
              </div>
            </div>
          </div>
        </>
      </Container>
    </div>
  );
}

export default Payment;

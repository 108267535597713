export const ENData = `   
<br/>
              <h2>About the dictionary</h2>
<br/>
              <div className="aboutUs_sec_2 ar ar-light">
   According to users, linguists and language experts, the dictionary
is Sweden's most comprehensive, most accurate and most
usuable dictionary ever.
<br/>
<br/>
Contains approximately 500,000 words, phrases, terms and
expressions
<br/>
<br/>
Is prepared by professional, highly educated and experienced
language experts.
<br/>
<br/>
Is adapted to suit a new language learner as well as an academic
and an experienced translator.
<br/>
<br/>
Covers almost everything in everyday life, science and other
specialist areas.
<br/>
<br/>
Each word is followed by part of speech, article or inflection
<br/>
<br/>
Numerous explanatory examples, especially for words that can
have multiple meanings.
<br/>
<br/>
Meaning analyses, definitions and explanations of terms and
other concepts.
<br/>
<br/>
We strongly dare to claim that there is no topic that is not
included in the dictionary.
<br/>
<br/>
            </div>`;

import React from "react";
import "./account-info.styles.scss";
import { Container } from "reactstrap";

const AccountInfo = (data) => {
  console.log("data", data);
  const userInfo = data.data;
  function search(formData) {
    const query = formData.get("query");
    alert(`You searched for '${query}'`);
  }

  return (
    <div className="AccountInfoBox">
      <Container>
        <h2>Account Info - معلومات الحساب</h2>

        <form action={search}>
          <div className="inputElement">
            <label>Full name - الاسم الكامل</label>
            <input
              name="fullName"
              value={userInfo.fullName ? userInfo.fullName : ""}
              disabled
            />
          </div>
          <div className="inputElement">
            <label>Mobile Number - رقم الهاتف</label>
            <input
              name="mobile"
              value={userInfo.mobile ? userInfo.mobile : ""}
              disabled
            />
          </div>
          <div className="inputElement">
            <label>E-mail - البريد الالكتروني</label>
            <input
              name="email"
              value={userInfo.email ? userInfo.email : ""}
              disabled
            />
          </div>
          <div className="inputElement">
            <label>Country - البلد</label>
            <input
              name="country"
              value={userInfo.country ? userInfo.country : ""}
              disabled
            />
          </div>
          <div className="inputElement">
            <label>Registration Date - تاريخ التسجيل</label>
            <input
              name="created_at"
              value={userInfo.created_at ? userInfo.created_at : ""}
              disabled
            />
          </div>
          {/*  <button type="submit" className="updateBtn">
            Update - تعديل
          </button> */}
        </form>
      </Container>
    </div>
  );
};

export default AccountInfo;

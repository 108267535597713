import React, { useState, useEffect } from "react";
import "./introduction.styles.scss";
import { Container, Row, Col } from "reactstrap";
import { TypeAnimation } from "react-type-animation";

const Introduction = () => {
  return (
    <div className="introduction mainIntroduction">
      <Container>
        <div className="introductioncontainer">
          <div className="introText" md={6} xs={6}>
            <TypeAnimation
              sequence={[
                "The giant Swedish-Arabic-English dictionary, author: Hussam Alkoblawi", // Types 'One'
                2000,
                "القاموس العملاق سويدي – عربي – انكليزي للمولف حسام القبلاوي",
                2000,
                "Daily update   -   Daglig uppdatering   - يتم تحديثه يومياً",
                2000,
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              style={{ fontSize: "1em", display: "inline-block" }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Introduction;

import React, { useState } from "react";
import "./translate-header.styles.scss";
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Arabic_Question from "../../assets/arabic_question.png";
import Template from "./help_data";
import { TranslationType } from "../../redux/translationType.enum";
import { useSelector } from "react-redux";
function TranslateHeader() {
  const app = useSelector((state) => state.app);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div className="translateHeader">
      <div className="left">
        {app.translationType === TranslationType.ENGLISH && (
          <h5 className="se se-bold">Translate to English</h5>
        )}
        {app.translationType === TranslationType.SWEDISH && (
          <h5 className="se se-bold">Översätt från svenska</h5>
        )}
        {app.translationType === TranslationType.ARABIC && (
          <div className="arabic_Title">
            <h5 className="ar ar-bold h5Label">ترجم من العربي</h5>

            <Button
              onClick={() => {
                toggle();
              }}
              className="questionButton"
            >
              <img className="questionImage" src={Arabic_Question} />
            </Button>
          </div>
        )}
      </div>

      <Modal isOpen={modal} toggle={toggle} className="ModalBox ar modal-lg">
        <ModalHeader className={"help_modal"}>
          نصائح للحصول على أفضل النتائج من القاموس
        </ModalHeader>
        <ModalBody>
          <Template />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default TranslateHeader;

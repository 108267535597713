import React, { useState, useEffect } from "react";
import "./books.styles.scss";
import { Link } from "react-router";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useSelector } from "react-redux";
import pdf from "../../assets/pdf.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Book from "../book/book.component";

import { Data } from "./data";

const Books = ({ data, language }) => {
  const app = useSelector((state) => state.app);
  const [books, Setbooks] = useState(Data);
  const [book, SetBook] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {}, [books]);
  const DownloadBook = (pdf) => {
    window.location.href = "http://157.175.57.19:8080" + pdf;
  };
  return (
    <div className="Books">
      <Container>
        <Carousel
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={true}
          ssr={true}
        >
          {books.map((element) => (
            <div
              className={
                element.Language == "Swedish" ? "swedish book" : "arabic book"
              }
              key={`element_books_${element.id}`}
            >
              {element.Language == "Swedish" && (
                <div className="book_image book_image_se">
                  {element.image && <img src={element.image} />}
                </div>
              )}
              {element.Language == "Swedish" ? (
                <div className="book_content">
                  <div className="book_title se se-bold">{element.title}</div>
                  <div
                    className="book_body se se-regular"
                    dangerouslySetInnerHTML={{
                      __html: element.body.substring(0, 100),
                    }}
                  ></div>
                  <div className="book_pdf">
                    <div className="btnBox">
                      <Button
                        onClick={() => {
                          SetBook(element);
                          toggle();
                        }}
                        className="ViewBtn ar ar-regular"
                      >
                        Mer
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="book_content">
                  <div className="book_title ar ar-bold">{element.title}</div>
                  <div
                    className="book_body ar ar-regular"
                    dangerouslySetInnerHTML={{
                      __html: element.body.substring(0, 100),
                    }}
                  ></div>
                  <div className="book_pdf">
                    <div className="btnBox">
                      <Button
                        onClick={() => {
                          SetBook(element);
                          toggle();
                        }}
                        className="ViewBtn ar ar-regular"
                      >
                        المزيد
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {element.Language == "Arabic" && (
                <div className="book_image book_image_ar">
                  {element.image && <img src={element.image} />}
                </div>
              )}
            </div>
          ))}
        </Carousel>
      </Container>
      <Modal isOpen={modal} toggle={toggle} className="ModalBox modal-lg">
        <ModalHeader
          className={
            book.Language == "Swedish"
              ? "swedish se se-bold"
              : "arabic ar ar-bold"
          }
        >
          {book.title}
        </ModalHeader>
        <ModalBody>
          <Book data={book} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Books;

import React, { useState } from "react";
import "./tabs.styles.scss";
import { Container } from "reactstrap";
import { Element } from "react-scroll";
import { useLocation } from "react-router-dom";
import Login from "../login/login.component";
import Register from "../register/register.component";
import ForgotPassword from "../forgot-password/forgot-password.component";
import ResetPassword from "../reset-password/reset-password.component";

const LoginRegisterPasswordTabs = () => {
  const [opentab, setOpenTab] = useState("register");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let showResetPassword = false;
  if (location.pathname === "/reset-password/") {
    const email = searchParams.get("email");
    const code = searchParams.get("code");
    if (email && code) {
      showResetPassword = true;
      setTimeout(() => setOpenTab("resetPassword"), 100);
    }
  }

  return (
    <div className="loginRegisterPasswordTabs" classID>
      <Container>
        <Element name="Register" className="element registerBox">
          <Container>
            <div className="loginTabs">
              <div
                className={
                  opentab === "login" ? "tab login active" : "tab login"
                }
                onClick={() => setOpenTab("login")}
              >
                LOGIN
              </div>
              <div
                className={
                  opentab === "register"
                    ? "tab register active"
                    : "tab register"
                }
                onClick={() => setOpenTab("register")}
              >
                REGISTER
              </div>
              <div
                className={
                  opentab === "forgotPassword"
                    ? "tab forgotPassword active"
                    : "tab forgotPassword"
                }
                onClick={() => setOpenTab("forgotPassword")}
              >
                Forgot Password
              </div>
              {showResetPassword && (
                <div
                  className={
                    opentab === "resetPassword"
                      ? "tab resetPassword active"
                      : "tab resetPassword"
                  }
                  onClick={() => setOpenTab("resetPassword")}
                >
                  Reset Password
                </div>
              )}
            </div>
          </Container>
          {opentab === "login" && <Login />}
          {opentab === "register" && <Register />}
          {opentab === "forgotPassword" && <ForgotPassword />}
          {opentab === "resetPassword" && <ResetPassword />}
        </Element>
      </Container>
    </div>
  );
};

export default LoginRegisterPasswordTabs;

import React, { useState, useEffect } from "react";
import "./used-terms.styles.scss";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import SearchIcon from "../../assets/search.png";
import { SERVER_URL } from "../../constants";
const UsedTerms = ({ categoryId, title }) => {
  console.log("UsedTerms categoryId", categoryId, title);
  const app = useSelector((state) => state.app);
  const [usedTerms, setUsedTerms] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [filteredResults, setFilteredResults] = useState([]);
  useEffect(() => {
    fetch(`${SERVER_URL}/used-terms/terms/${categoryId}`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Authorization: app.token,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response && response.length > 0) {
          setUsedTerms(response[0].terms);
          setFilteredResults(response[0].terms);
        }
      });
  }, []);

  const searchValue = (event) => {
    const result = filteredResults.filter((val) => {
      if (JSON.stringify(val).includes(event.target.value)) {
        return val;
      }
    });

    setFilteredResults(result);
    if (event.target.value === "") {
      setFilteredResults(usedTerms);
    }
  };
  return (
    <div className="UsedTermsElement">
      <Container>
        <div className="title">
          <div className="arabic">{title.arabic}</div>
          <div className="english">{title.english}</div>
          <div className="english">{title.swedish}</div>
        </div>
        <div className="searchBox">
          <input
            name="body"
            className="searchInput"
            placeholder="ابحث - Search  -  Sök"
            required
            onChange={searchValue}
          />
          <img src={SearchIcon} className="searchIcon" />
        </div>
        <div className="terms">
          {filteredResults.length > 0 &&
            filteredResults.map((term) => (
              <div className="usedTermrecord">
                {term.body.arabic && (
                  <div className="arabicTitle element">
                    <div className="label">عربي:</div>
                    <div
                      className="termBody"
                      dangerouslySetInnerHTML={{ __html: term.body.arabic }}
                    ></div>
                  </div>
                )}
                {term.body.english && (
                  <div className="englishTitle element">
                    <div className="label">English:</div>
                    <div
                      className="termBody"
                      dangerouslySetInnerHTML={{ __html: term.body.english }}
                    ></div>
                  </div>
                )}
                {term.body.swedish && (
                  <div className="swedishTitle element">
                    <div className="label">Swedish:</div>
                    <div
                      className="termBody"
                      dangerouslySetInnerHTML={{ __html: term.body.swedish }}
                    ></div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </Container>
    </div>
  );
};

export default UsedTerms;

export const SEData = `   
<br/>
              <h2>Om ordboken</h2>
<br/>
              <div className="aboutUs_sec_2 ar ar-light">
  Ordboken är enligt användare, språkvetare och språkexperter,
Sveriges någonsin mest omfattande mest ackurata och mest
användbara ordbok.
<br/>
<br/>
Innehåller ca 500 000 ord, fraser, termer och uttryck
<br/>
<br/>
Är utarbetad av professionella, högutbildade och erfarna
språkexperter.
<br/>
<br/>
Är anpassad för att passa en nybliven språkelev likaväl som
en akademiker och en erfaren översättare.
<br/>
<br/>
Täcker nästan allt inom vardagsliv, vetenskap och övriga
fackområden.
<br/>
<br/>
Varje ord följes av ordklass, artikel eller böjning
<br/>
<br/>
Åtskilliga förklarande exempel, i synnerhet på ord som kan ha
flera betydelser.
<br/>
<br/>
Betydelseanalyser, definitioner och förklaringar av termer och
andra begrepp.
<br/>
<br/>
Vi vågar starkt påstå att det inte finns något ämne som inte
tagits med i ordboken.
<br/>
<br/>
            </div>`;

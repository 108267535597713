import React, { useState, useEffect } from "react";
import "./App.css";
import { Switch, Route, useLocation } from "react-router-dom";
import HomePage from "./screens/homeScreen/homeScreen.component";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import arrowup from "./assets/arrow-up.png";
import AccountPage from "./screens/account/account.screen";
import AppPaymentPage from "./screens/app-payment/app-payment";
import ResetPassword from "./components/reset-password/reset-password.component";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

const DEFAULT_CONFIG = {
  trackingId: "UA-176600842-1",
  debug: true,
  gaOptions: {
    cookieDomain: "none",
  },
};

function App() {
  const location = useLocation();
  var subtitle;
  const [modalIsOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const [reactGaInitialised, setReactGaInitialised] = useState(false);
  const [configs, SetConfigs] = useState([DEFAULT_CONFIG]);
  const history = createBrowserHistory();

  const ScrollToTopButton = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling behavior
    });
  };

  // Analytics

  const filteredConfigs = () => {
    return configs.filter(({ trackingId: id }) => id);
  };

  const initReactGA = (event) => {
    if (filteredConfigs().length === 0) {
      return;
    }
    ReactGA.initialize(configs);
    history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    setReactGaInitialised({ reactGaInitialised: true });
  };

  function initializeReactGA() {
    ReactGA.initialize("UA-176600842-1");
    ReactGA.pageview("/homepage");
  }

  useEffect(() => {
    initializeReactGA();
  }, []);

  // End of Analytics
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="HelloModal">
      {location.pathname == "/" && (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2
            style={{ textAlign: "right", direction: "rtl" }}
            ref={(_subtitle) => (subtitle = _subtitle)}
          >
            مرحبا
          </h2>
          <div style={{ textAlign: "right", direction: "rtl" }}>
            <p>
              نصائح الاستخدام للترجمة من العربية للسويدية للحصول على أفضل
              النتائج:
            </p>
            <ul>
              <li>- للبحث المتطور و الحصول على خيارات عديدة اضغط "ابحث"</li>
              <li>
                - في حال عدم ظهور الكلمة التي تبحث عنها ان كنت قد كتبتها مضافاً
                لها ألف و لام التعريف ابحث عن نفس الكلمة بعد حذف ألف و لام
                التعريف، و العكس صحيح
              </li>
              <li>
                - اكتب أول ثلاثة أحرف من الكلمة المراد البحث عنها و اعطي محرك
                البحث بضعة ثوان و ستظهر لك قائمة بكل الكلمات التي تبدأ بتلك
                الأحرف و حينها تختار كلمتك من القائمة بالضغط عليها
              </li>
            </ul>
            <button onClick={closeModal}>اغلاق</button>&nbsp;&nbsp;
            <button onClick={closeModal}>Stäng</button>
          </div>
        </Modal>
      )}
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/account" component={AccountPage} />
        <Route exact path="/app-dafe3" component={AppPaymentPage} />
        <Route exact path="/reset-password" component={ResetPassword} />
      </Switch>
      <div className="uparrow" onClick={() => ScrollToTopButton()}>
        <img className="arrowupImage" src={arrowup} />
      </div>
    </div>
  );
}

export default App;

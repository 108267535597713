export const ARData = `    
<br/> 
        <h2 style="text-align:right">حول القاموس</h2>
<br/>
<div className="aboutUs_sec_2 ar ar-light" style="text-align: right;">
                وفقا للمستخدمين واللغويين الضالعين وخبراء اللغة، فإن هذا القاموس هو
.القاموس السويدي األكثر شموال ودقة واألكثر فائدة على اإلطالق
                <br />
                <br />
يحتوي على حوالي 500.000 كلمة وعبارة ومصطلح وتعبير
                <br />
                <br />
.تم إعداده من قبل خبراء لغة محترفين ومدربين تدريباً عالياً وذوي خبرة
                <br />
                <br />
تم تكييفه ليناسب متعلم اللغة الجديد والطالب الجامعي و المترجمين المحترفين
.وذوي الخبرة
<br/>
<br/>
يغطي كافة المجاالت في الحياة اليومية وشتى الفروع العلمية التخصصات
. المختلفة والعلوم االنسانية و التطبيقية
<br/>
<br/>
العديد من األمثلة التوضيحية، وخاصة الكلمات التي يمكن أن يكون لها معاني
.متعددة
<br/>
<br/>
تصريف األفعال، أدوات التعريف وغيرهما من األمور النحوية
  <br/>
<br/>
          ٍ.تحليل المعنى والتعاريف والتفسيرات للمصطلحات والمفاهيم مع شرح واف
  <br/>
<br/>
يتبع القاموس األسلوب السهل في الكتابة ولذلك لم نقم باتباع أسلوب جذر -
الكلمة أو مصدرها وذلك لتسهيل االستخدام والفهم من كل الفئات متفاوتة
  <br/>
<br/>
نجرؤ بقوة على القول بأنه ال يوجد موضوع لم يتم إدراجه في القاموس
  <br/>
<br/>
            </div>`;

import React, { useState, useEffect } from "react";
import "./used-terms-categories.styles.scss";
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useSelector } from "react-redux";
import UsedTerms from "../used-terms/used-terms.component";
import { SERVER_URL } from "../../constants";
const UsedTermsCategories = ({ data, language }) => {
  const app = useSelector((state) => state.app);
  const [usedTerms, setUsedTerms] = useState([]);
  const [modal, setModal] = useState(false);
  const [termCategory, setTermCategory] = useState();
  const toggle = () => setModal(!modal);
  useEffect(() => {
    fetch(`${SERVER_URL}/used-terms/categories`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Authorization: app.token,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response && response.length > 0) {
          setUsedTerms(response);
        }
      });
  }, []);
  return (
    <div className="UsedTerms">
      <Container>
        <div className="terms">
          {usedTerms.length > 0 &&
            usedTerms.map((term, index) => (
              <div
                className={`usedTerm ${term?.size ? `size-${term.size}` : ""}`}
                onClick={() => {
                  console.log({ id: term.id, title: term.title });
                  setTermCategory({ id: term.id, title: term.title });
                  toggle();
                }}
                key={`UsedTermsCategories_${index}`}
              >
                <div className="imageDiv">
                  <img
                    src={`https://kamosarabi.online:4433/uploads/${term.image}`}
                    className="image"
                    alt={term.title.arabic}
                  />
                </div>
                <div className="arabicTitle">{term.title.arabic}</div>
              </div>
            ))}
        </div>
      </Container>
      <Modal isOpen={modal} toggle={toggle} className="ModalBox modal-xl">
        <ModalHeader>
          <Button color="secondary" onClick={toggle}>
            اغلاق
          </Button>
        </ModalHeader>
        <ModalBody>
          {termCategory && termCategory.id && (
            <UsedTerms
              categoryId={termCategory.id}
              title={termCategory.title}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UsedTermsCategories;

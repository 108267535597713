import React, { useState } from 'react';
import { PaymentElement,CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStatus, setPaymentStatus] = useState('');

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     // Stripe.js hasn't loaded yet
  //     return;
  //   }

  //   const cardElement = elements.getElement(CardElement);

  //   // Send payment request to your server to create PaymentIntent
  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: 'card',
  //     card: cardElement,

  //   });

  //   if (error) {
  //     setPaymentStatus(`Payment failed: ${error.message}`);
  //   } else {
  //     setPaymentStatus('Payment successful!');
  //     // Here you can send the paymentMethod.id to your backend for processing
  //   }
  // };

  return (
    <div>
      <form id="payment-form">
        <label>Pay</label>
        <CardElement></CardElement>
<button>Pay</button>
      </form>
    </div>
  );
};

export default CheckoutForm;
import React, { useState, useEffect } from "react";
import "./box.styles.scss";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useSelector } from "react-redux";
import StaticPage from "../static-page/static-page.component";
const Box = ({ data, language }) => {
  const BoxData = data;
  const Boxlanguage = language;
  const app = useSelector((state) => state.app);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div className="BoxModal">
      <div className="Box">
        {BoxData.image && (
          <div className="icon">
            <img src={BoxData.image} />
          </div>
        )}
        <Row className="title">
          <Col
            className="se se-bold"
            dangerouslySetInnerHTML={{ __html: BoxData.title_se }}
          ></Col>
          <Col
            className="ar ar-bold"
            dangerouslySetInnerHTML={{ __html: BoxData.title_ar }}
          ></Col>
        </Row>
        <div
          className="introduction ar ar-light"
          dangerouslySetInnerHTML={{ __html: BoxData.Introduction_ar }}
        ></div>
        {/* <div
          className="introduction se se-light"
          dangerouslySetInnerHTML={{ __html: BoxData.introduction_se }}
        ></div> */}

        <div className="btnBox">
          <Button onClick={toggle} className="ViewBtn ar ar-regular">
            Mer-المزيد
          </Button>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} className="ModalBox modal-lg">
        <ModalHeader
          className={
            data.Language == "Swedish"
              ? "swedish se se-bold"
              : "arabic ar ar-bold"
          }
        >
          {data.Title}
        </ModalHeader>
        <ModalBody>
          <StaticPage data={BoxData} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Box;

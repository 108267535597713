import React, { useEffect, useState } from "react";
import "./popup.styles.scss";
import Bell from "../../assets/bell.png";
import { useSelector } from "react-redux";
import { SERVER_URL } from "../../constants";
const Popup = () => {
  const app = useSelector((state) => state.app);
  const [isOpen, setIsOpen] = useState(false);
  const [popupInfo, setPopupInfo] = useState({});
  useEffect(() => {
    fetch(`${SERVER_URL}/popup`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response && response.status) {
          setPopupInfo(response);
          setIsOpen(true);
        }
      });
  }, []);
  const closePopup = () => {
    setIsOpen(false);
  };
  if (!isOpen) return null;
  return (
    <>
      {JSON.stringify(popupInfo) !== "{}" && (
        <div className="popup">
          <div className="popup-overlay" onClick={closePopup}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              <img src={Bell} alt="Popup Image" className="popup-image" />
              <h2 className="popup-title">{popupInfo.title}</h2>
              <p className="popup-message">{popupInfo.body}</p>
              <button className="popup-close" onClick={closePopup}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;

import React, { useState } from "react";
import "./about-us.styles.scss";
import { Container, Row, Col } from "reactstrap";
import DoctorHussam from "../../assets/doctor_hussam.png";
import { ARData } from "./data-ar";
import { ENData } from "./data-en";
import { SEData } from "./data-se";

const AboutUs = () => {
  const [language, setLanguage] = useState("SE");
  return (
    <div className="AboutUs" classID>
      <Container>
        <Row>
          <Col xs={12} md={6} className="bodysec">
            <div className="language">
              <button
                onClick={() => setLanguage("SE")}
                className={language === "SE" ? "active" : ""}
              >
                SE
              </button>
              <button
                onClick={() => setLanguage("EN")}
                className={language === "EN" ? "active" : ""}
              >
                EN
              </button>
              <button
                onClick={() => setLanguage("AR")}
                className={language === "AR" ? "active" : ""}
              >
                AR
              </button>
            </div>
            {language === "SE" && (
              <div dangerouslySetInnerHTML={{ __html: SEData }} />
            )}
            {language === "AR" && (
              <div dangerouslySetInnerHTML={{ __html: ARData }} />
            )}
            {language === "EN" && (
              <div dangerouslySetInnerHTML={{ __html: ENData }} />
            )}
          </Col>
          <Col xs={12} md={6}>
            <img src={DoctorHussam} width="100%" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;

import React, { useState, useEffect } from "react";
import "./testemonials.styles.scss";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { SERVER_URL } from "../../constants";
const Testemonials = ({ data, language }) => {
  const app = useSelector((state) => state.app);
  const [comments, setComments] = useState([]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    if (comments.length == 0) {
      fetch(`${SERVER_URL}/comments/website`, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          Authorization: app.token,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          setComments(response);
        });
    }
  }, []);

  return (
    <div className="Testemonials">
      <Container className="testemonialsConatiner">
        <div className="carouselContainer">
          <Carousel
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots={true}
            ssr={true}
          >
            {comments.map((element, index) => (
              <div className="testemonials" key={`testmonials_${index}`}>
                <div className="text">{element.body}</div>
                <div className="writer">{element.writer}</div>
                <div className="position">{element.position}</div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="whatTheySaid">
          <div className="internal">ماذا قال الخبراء</div>
        </div>
      </Container>
    </div>
  );
};

export default Testemonials;

import AppActionTypes from "./app.types";
import { TranslationType } from "../translationType.enum";
const INITIAL_STATE = {
  addtohomescreen: false,
  translationType: TranslationType.SWEDISH,
  token: "",
  openings: 0,
  user_profile: {},
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionTypes.SET_OPENED_CATEGORY:
      return {
        ...state,
        openedCategory: action.payload.name,
      };
    case AppActionTypes.SET_TRANSLATION_TYPE:
      return {
        ...state,
        translationType: action.payload,
      };
    case AppActionTypes.ADD_OPENING:
      return {
        ...state,
        openings: state.openings + 1,
      };
    case AppActionTypes.TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case AppActionTypes.USER_PROFILE:
      return {
        ...state,
        user_profile: action.payload,
      };
    case AppActionTypes.LOGOUT:
      return {
        ...state,
        user_profile: {},
        token: "",
      };
    default:
      return state;
  }
};

export default appReducer;

export const ENData = `   
<br/>
<br/>
              <h2>About Us</h2>
<br/>
<br/>
              <div className="aboutUs_sec_2 ar ar-light">
    <h3>The author:</h3>
<br/>
<br/>
             PhD. Hussam Alkoblawi is a Swedish citizen of Palestinian
origin, born in Lebanon. He has a high academic
education from both Sweden and Egypt. He worked as a
researcher and held a doctoral position at the Department

of Geology, Lund University. He also worked as a language
educator at the Literature and Language Center, Lund
University, and as a lecturer in the School Act for Newly
Arrived Students with Foreign Teacher Training, Malmö
University.
              <br />
              <br />
            </div>
            <div className="aboutUs_sec_2 ar ar-light" >
             In 1992, he became Skåne's first authorized interpreter
between Swedish and Arabic, and is now a legal
interpreter, interpreting teacher, language instructor and
lecturer in the subjects of integration and culture.
Alkoblawi has previously published five dictionaries. He
has also published other non-fiction books and novels.
             
            </div>`;

import React, { useState, useEffect } from "react";
import "./header.styles.scss";
import {
  Container,
  Row,
  Col,
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarToggler,
  NavbarBrand,
} from "reactstrap";
import Logo from "../logo/Logo.component";
import { Link, animateScroll as scroll } from "react-scroll";
import { BrowserRouter as Router, Link as MoveLink } from "react-router-dom";
import UserIcon from "../../assets/user.png";
import { useLocation } from "react-router-dom";
import HomeIcon from "../../assets/homeIcon.png";
import { useSelector } from "react-redux";

const Header = () => {
  const location = useLocation();
  const app = useSelector((state) => state.app);
  console.log("header app", app);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const handleClick = () => {
    setIsOpen(false);
  };
  return (
    <div className="header">
      <Navbar color="light" light expand="xl">
        <NavbarBrand>
          <a href="/" className="linkHref">
            <Logo />
          </a>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          {location.pathname === "/" ? (
            <Nav className="mr-auto" navbar>
              <NavItem>
                <Link
                  activeClass="active"
                  to="contactus"
                  spy={true}
                  onClick={handleClick}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <div className="se se-bold">Kontakta oss</div>
                  <div className="ar ar-bold">تواصل معنا</div>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  activeClass="active"
                  to="books"
                  spy={true}
                  onClick={handleClick}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <div className="se se-bold">Böcker</div>
                  <div className="ar ar-bold">الكتب</div>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  activeClass="active"
                  to="usedterms"
                  onClick={handleClick}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <div className="se se-bold">Vanliga termer</div>
                  <div className="ar ar-bold">مصطلحات شائعة</div>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  activeClass="active"
                  to="aboutus"
                  onClick={handleClick}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <div className="se se-bold">Om oss</div>
                  <div className="ar ar-bold">من نحن</div>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  activeClass="active"
                  to="testimonials"
                  onClick={handleClick}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <div className="se se-bold">Vad sa språkexperter</div>
                  <div className="ar ar-bold">ماذا قال الخبراء</div>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  activeClass="active"
                  to="ThisDictionary"
                  onClick={handleClick}
                  spy={true}
                  smooth={true}
                  offset={-112}
                  duration={500}
                >
                  <div className="se se-bold">Om ordboken</div>
                  <div className="ar ar-bold">حول القاموس</div>
                </Link>
              </NavItem>
              <NavItem>
                <a href="/account" className="linkHref">
                  <div className="se se-bold">
                    Mitt konto <img src={UserIcon} className="userIcon" /> حسابي
                  </div>
                </a>
              </NavItem>
            </Nav>
          ) : (
            <Nav className="myAccount" navbar>
              <NavItem>
                <a href="/" className="linkHref">
                  <div className="se se-bold">
                    Hem <img src={HomeIcon} className="userIcon" /> الرئيسية
                  </div>
                </a>
              </NavItem>
            </Nav>
          )}
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
